import React from 'react';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';

import Copyright from '../common/footer/Copyright';
import ScrollTop from '../common/footer/ScrollTop';
import PortfolioOne from '../elements/portfolio/PortfolioOne';
import BrandThree from '../elements/brand/BrandThree';

import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";


const CreativePortfolio = () => {
    return (
        <>
            <SEO title="Our Services" />
            <main className="page-wrapper">
               

                {/* Start Slider area  */}
                <div className="slider-area slider-style-7 height-650">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h4 class="subtitle rn-sub-badge"><span class="theme-gradient">Our Services</span></h4>
                                    <h3 className="title">Unleash Your Technology Potential</h3>
										
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider area  */}


                <div className="rwt-portfolio-area">
                    <div className="container-fluid plr--60 plr_md--15 plr_sm--15">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Where We Can Help"
                                    title = "Our Services Boost Your Growth!"
                                    description = ""
                                />
                            </div>
                        </div>
                        <PortfolioOne Column="col-lg-4 mt--30 box-grid-layout no-overlay" />
                    </div>
                </div>


                {/* Start Brand Area  */}
                <div className="rwt-brand-area ptb--90 d-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandThree brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

            </main>
        </>
    )
}
export default CreativePortfolio;