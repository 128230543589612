import React from 'react';
import Typed from 'react-typed';
import { Link } from "react-router-dom";
const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/choose-us.jpg" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Why Choose TechSalts <br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Custom Solutions.",
                                            "Reliability.",
                                            "Client-Centric Approach.",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>Our team consists of seasoned professionals with a deep understanding of technology and business. We tailor our services to meet your unique requirements and objectives.
								<br/>Count on us to deliver high-quality results within agreed timelines and budgets. Your satisfaction is our priority. We collaborate closely with you to ensure we exceed your expectations every step of the way.</p>
								<p>Partner with TechSalts and let us help you unlock the full potential of your business through innovative technology solutions and strategic guidance.</p>
                                <div className="read-more-btn mt--40">
                                    <Link className="btn-default" to="/contact">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne
