import React from "react";
import ReactDOM from "react-dom/client";
import SEO from "./common/SEO";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <>
    
        <App />
  </>
);
