import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        date: "Step-1",
        title: "Knowledge",
        description: "Our process begins with a deep dive into understanding your business, its unique challenges, and strategic objectives.",
        image: "knowledge",
        workingStep: [
            {
                stepTitle: "Gather comprehensive insights",
                stepDescription: "We gather comprehensive insights into your current technology landscape, existing systems, and infrastructure",
            },
            {
                stepTitle: "Research & Analysis",
                stepDescription: "This phase involves thorough research and analysis to establish a solid foundation of knowledge about your organization and its technical requirements",
            },
        ]
    },
    {
        id: "2",
        date: "Step-2",
        title: "Analysis",
        description: "Building upon the knowledge acquired, we conduct rigorous analysis and assessment of key aspects pertinent to your needs. For software due diligence, we scrutinize code quality, architecture, security protocols, and scalability potential.",
        image: "analysis",
        workingStep: [
            {
                stepTitle: "virtual CTO",
                stepDescription: "In virtual CTO services, we analyze existing technology strategies, identify areas for improvement, and align them with your business goals",
            },
            {
                stepTitle: "Technical Hiring",
                stepDescription: "In technical remote hiring, we assess candidate profiles, skills, and cultural fit against your organizational requirements",
            },
        ]
    },
	{
        id: "3",
        date: "Step-3",
        title: "Process",
        description: "Based on the insights gained from our analysis, we develop customized strategies and implementation plans tailored to your specific needs.",
        image: "process",
        workingStep: [
            {
                stepTitle: "Clarity",
                stepDescription: "We meticulously outline step-by-step processes, define clear objectives, and establish measurable milestones",
            },
            {
                stepTitle: "Efficiency & Transparency",
                stepDescription: "Our approach emphasizes efficiency, transparency, and collaboration, ensuring that every aspect of our engagement aligns with your overarching goals and timelines.",
            },
        ]
    },
	{
        id: "4",
        date: "Step-4",
        title: "Validating",
        description: "Validation forms a crucial part of our process, where we ensure that the solutions implemented meet your expectations and deliver tangible value.",
        image: "validate",
        workingStep: [
            {
                stepTitle: "Performance Evaluation",
                stepDescription: "We validate the effectiveness of our strategies through thorough testing, performance evaluation, and feedback mechanisms",
            },
            {
                stepTitle: "Optimization",
                stepDescription: "Continuous monitoring and optimization enable us to validate the sustainability and scalability of our solutions, ensuring that they remain aligned with your evolving business requirements",
            },
        ]
    },
]

const TimelineOne = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <span className="date-of-timeline">{data.date}</span>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h2 className="title">{data.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p className="description">{data.description}</p>
                                        </ScrollAnimation>

                                        <div className="row row--30">
                                            {data.workingStep.map((data, index)=>(
                                                <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                    <div className="working-list">
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <h5 className="working-title">{data.stepTitle}</h5>
                                                        </ScrollAnimation>

                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <p>{data.stepDescription}</p>
                                                        </ScrollAnimation>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/timeline/${data.image}.jpg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
           
            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOne;
