import React from 'react';
import CalltoActionFour from '../calltoaction/CalltoActionFour';
import Separator from "../separator/Separator";
import SEO from "../../common/SEO";
const PortfolioDetailsContent = ({ data }) => {
    return (
        <>
            <SEO title={data.title} />
        <div className="rwt-portfolio-details rn-section-gap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="inner">
                            <div className="details-list">
                                <div className="thumbnail alignwide d-none">
                                    <img className="radius w-100" style={{ height: "200px" }} src={`${process.env.PUBLIC_URL}/${data.largeImage}`} alt="Corporate Image" />
                                </div>

                                <div className="row mt--40 row--30">

                                    <div className="col-lg-12 mt_md--30 mt_sm--30">
                                        <div className="content text-center mb-5">
                                            <div className="page-title">
                                                <h1 className="theme-gradient">{data.title}</h1>
                                                </div>
                                                <h3 className="subtitle">{data.subtitle}</h3>
                                                
                                        </div>


                                        <div className="content-right">
                                            
                                            <div className="description" dangerouslySetInnerHTML={{ __html: data.body }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Separator />
            {/* Start Elements Area  */}
            <div className="rwt-callto-action-area mt-5">
                <div className="wrapper">
                    <CalltoActionFour />
                </div>
            </div>
            {/* End Elements Area  */}
            </div>
        </>
    )
}

export default PortfolioDetailsContent;
