import React from 'react';
import {Link} from "react-router-dom";
import PortfolioData from "../../data/portfolio/PortfolioData.json";


const Nav = () => {
	const alldata = PortfolioData;
    return (
        <ul className="mainmenu">
		<li><Link to="/">Home</Link></li>
            
            
            <li><Link to="/about-us">About</Link></li>
            <li className="has-droupdown"><Link to="/services">Services</Link>
                <ul className="submenu">
				{alldata.map((item) => (
				
				<li><Link to={process.env.PUBLIC_URL + `/portfolio-details/${item.id}`}>{item.title}</Link></li>
                    
                ))}
                    
                </ul>
            </li>
			<li><Link to="/blog-grid-view">Blog</Link></li>
            

            

            <li className="has-droupdown"><Link to="/contact">Contact</Link></li>
        </ul>
    )
}
export default Nav;
