import React, { Component } from "react";

class GoogleMapStyle extends Component {
  render() {
    return (
      // Important! Always set the container height explicitly

      <div className="google-map-style-1">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d27653.553536564807!2d31.241050762621157!3d29.959473679174007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s1270%20new%20maadi!5e0!3m2!1sen!2ssa!4v1708977007312!5m2!1sen!2ssa"
          width="100%"
          height="100%"
          style={{ borderRadius: "10px", position: "relative", border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    );
  }
}

export default GoogleMapStyle;
