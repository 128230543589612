import React from 'react';
import BlogClassicData from '../data/blog/BlogList.json';
import BlogDetailsContent from '../components/blog/BlogDetailsContent';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import { Helmet } from 'react-helmet';

const BlogDetails = ({match: {params: {id}}}) => {
    const blogId = parseInt(id, 10)
    const data = BlogClassicData.filter(blog => blog.id === blogId);
    return (
        <>
            <div>
                <Helmet>
                    <title>{data[0].title} | TechSalts Blog</title>
                    <meta name="description" content={data[0].content} />
                </Helmet>
                {/* Your other React component content here */}
            </div>
            <>
                <div className="rn-blog-details-area">
                    <BlogDetailsContent data={data[0]}  />
                </div>
            </>
        </>
    )
}
export default BlogDetails;