import React from 'react';
import VideoTwo from '../video/VideoTwo';
import { FiCheck } from "react-icons/fi";
import {Link} from "react-router-dom";

const PopupData = [
    {
        id: "01",
        image: "./images/bg/bg-image-4.jpg",
        popupLink: [
            'https://www.youtube.com/watch?v=ctsT5Y-InqE&ab_channel=Rainbow-Themes',
        ],
    }
]

const AboutFour = ({image}) => {
    return (
        <div className="about-area about-style-4 rn-section-gap" id="#about-us">
            <div className="container">
                <div className="row row--40 align-items-center">
                    <div className="col-lg-6">
                        {PopupData.map((item) => (
                            <div className="video-btn" key={item.id}>
                                <VideoTwo imagename={`${image}`} galleryItem={item} />
                            </div>
                        ))}
                    </div>
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="inner">
                                <h3 className="title">We help your business in <br /> <strong>Different Areas.</strong></h3>
                                <ul className="feature-list">
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Develope Fintech Platforms?</h4>
                                            <p className="text">We Understanding Your Needs, Exceeding Your Vision.</p>
                                        </div>
                                    </li>
									<li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Ensuring Investment Confidence?</h4>
                                            <p className="text">Gain investor trust through rigorous software Due-Diligence.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Need Tech Talent Fast?</h4>
                                            <p className="text">Streamlined hiring for exceptional technical expertise, where Expertise meets Execution.</p>
                                        </div>
                                    </li>
									<li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Seeking Strategic Tech Leadership?</h4>
                                            <p className="text">Elevate your startup business with virtual CTO expertise.</p>
                                        </div>
                                    </li>
                                </ul>
								<Link className="btn-default btn-icon" to={process.env.PUBLIC_URL + `/Services`}>Explore Our Services</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFour
