import React from 'react'
import SEO from "../common/SEO";

import AboutFour from '../elements/about/AboutFour';
import AdvanceTabTwo from '../elements/advancetab/AdvanceTabTwo';
import CounterUpTwo from '../elements/counterup/CounterUpTwo';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import CalltoActionTwo from '../elements/calltoaction/CalltoActionTwo';
import TeamTwo from '../elements/team/TeamTwo';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import ContactForm from "../elements/contact/Contact";
import { Helmet } from 'react-helmet';
const Consulting = () => {
    return (
        <>
            <div>
                <Helmet>
                    <title>TechSalts | Pioneering Tech Solutions For Future Success</title>
                    <meta name="description" content="SOLUTION FOR YOUR FINTECH BUSINESS" />
                </Helmet>
                {/* Your other React component content here */}
            </div>
            <SEO title="TechSalts | Pioneering Tech Solutions For Future Success" description="About Us" />
            <main className="page-wrapper">
                

                {/* Start Slider area  */}
                <div className="slider-area slider-style-2 variation-2  height-850 bg_image" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/main-bg.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-xl-6">
                                <div className="inner text-start">
                                    <h1 className="title display-one">Pioneering <br/>Tech Solutions <br/>
									For Future Success</h1>
                                    <h6 className="tag-title">SOLUTION FOR YOUR BUSINESS</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider area  */}
				
                <AboutFour image="./images/about/contact-image.png" />

                {/* Start Elements Area  */}
                <div className="rwt-counterup-area rn-section-gapBottom mt_dec--30">
                    <div className="container">
                        <CounterUpTwo column="col-lg-4 col-md-6 col-sm-6 col-12" counterStyle="counter-style-1" textALign="text-center" />   
                    </div>
                </div>
                {/* End Elements Area  */}

				<Separator />
                {/* Start Elements Area -- Blog  */}
                <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="wrapper">
                        <CalltoActionFive />
                    </div>
                </div>
                {/* End Elements Area  */}
                <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-advance-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "More About Our Amazing Services"
                                    title = "Software Due Diligence, Virtual CTO, and Technical Hiring that's hi-tech, cost-effective, and swift. Explore how our services drive your success."
                                    description = ""
                                />
                            </div>
                        </div>
                        <AdvanceTabTwo />
                    </div>
                </div>
                {/* End Elements Area  */}


                


                {/* Start Elements Area  */}
                <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="wrapper">
                        <CalltoActionTwo />
                    </div>
                </div>
                {/* End Elements Area  */}
				
				{/* Start Elements Area  */}
				<div className="rwt-callto-action-area rn-section-gapBottom d-none">
                    <div className="wrapper">
                
                        



                    </div>
                </div>
                {/* End Elements Area  */}
                
            </main>

        </>
    )
}

export default Consulting
