import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";
import BlogProp from './itemProp/BlogProp';
import { Helmet } from 'react-helmet';


const BlogGridView = () => {
    return (
        
            
        <>
            <div>
                <Helmet>
                    <title>TechSalts Blog</title>
                    <meta name="description" content="Dive into the World of Technology with TechSalts" />
                </Helmet>
                {/* Your other React component content here */}
            </div>
			<SEO title="Blog | TechSalts" />
                <BreadcrumbOne 
                    title="Dive into the World of Technology with TechSalts"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Blog List"
                />
                <div className="main-content">
                    {/* Start Blog Area  */}
                    <div className="rn-blog-area rn-section-gap">
                        <div className="container">
                            <div className="row mt_dec--30">
                                <BlogProp column="col-lg-4 col-md-6 col-12 mt--30" StyleVarProp="box-card-style-default" />
                            </div>
                        </div>
                    </div>
                    {/* End Blog Area  */}
                </div>
            </>
        
    )
}
export default BlogGridView;