import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiActivity />,
        title: 'Software Due Diligence',
        description: 'We offer comprehensive software due diligence services to help businesses make informed decisions when evaluating potential investments, partnerships, or acquisitions. Our rigorous analysis and assessment process ensure that you have a clear understanding of the technical aspects and risks associated with your ventures.'
    },
    {
        icon: <FiCast />,
        title: 'Virtual CTO',
        description: 'Leverage our virtual Chief Technology Officer (CTO) services to gain strategic technical guidance and leadership without the overhead of a full-time executive. Our seasoned CTOs work closely with your team to align technology initiatives with your business objectives, driving innovation and scalability.'
    },
    {
        icon: <FiMap />,
        title: 'Technical Hiring',
        description: 'Finding top technical talent can be challenging. TechSalts simplifies the process with our technical remote hiring services. Whether you need software developers, engineers, or IT professionals, we connect you with skilled individuals who are the right fit for your organization needs and culture.'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
						
                    </ScrollAnimation>
                </div>
				
            ))}
			
			<div className="call-to-btn text-center mt-5">
                                        
										<Link className="btn-default btn-icon" to={process.env.PUBLIC_URL + `/services`}>{"Explore Our Services"}</Link>
                                    </div>
        </div>
    )
}
export default ServiceOne;