import React from 'react'
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderOne from '../common/header/HeaderOne';
import FooterFour from '../common/footer/FooterFour';
import BrandThree from '../elements/brand/BrandThree';
import AboutOne from '../elements/about/AboutOne';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineOne from "../elements/timeline/TimelineOne";
import Separator from "../elements/separator/Separator";
import { Helmet } from 'react-helmet';

const AboutUs = () => {
    return (
        <>
            <div>
                <Helmet>
                    <title>About TechSalts</title>
                    <meta name="description" content="Empowering businesses with top-notch technical solutions" />
                </Helmet>
                {/* Your other React component content here */}
            </div>
            <main className="page-wrapper">
                

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/about-bg.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Welcome to TechSalts, where</span></h3>
                                    </div>
                                    <h2 className="title display-one">We leverage our technical prowess to drive innovation and growth  <br /> For Your Business</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Empowering businesses with top-notch technical solutions</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">At TechSalts, our mission is to empower businesses with top-notch technical solutions and expertise to drive innovation and growth. We strive to be the premier destination for companies seeking excellence in software due diligence, virtual CTO services, and technical remote hiring.
</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}



                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "Services provide for you."
                                    description = "With years of experience in the tech sector, our professionals bring a wealth of knowledge and insight to every project we undertake."
                                    />
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                <div className="rwt-timeline-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <TimelineOne classVar="no-gradient"  />
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />

                <AboutOne image="./images/about/contact-image.jpg" />
                

                <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-team-area rn-section-gap d-none">
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Corporate Team."
                                    title = "Corporate Team Member."
                                    description = ""
                                />
                            </div>
                        </div>
                        <TeamFour column="col-lg-6 col-xl-3 col-md-6 col-12 mt--30" teamStyle="team-style-three" />
                    </div>
                </div>
                {/* End Elements Area  */}


            </main>
        </>
    )
}

export default AboutUs;
